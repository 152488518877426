// src/pages/Services.js
import React from "react";
import { Link } from "react-router-dom";
import MainScreenInterface from "../assets/main-screen-interface.png"; // パスを適宜調整してください
import ChatTreeLogo from "../assets/chat-tree-logo.png"; // パスを適宜調整してください

function Services() {
  return (
    <div className="bg-white min-h-screen">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-[#004aad] to-[#38b6ff] text-white py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <img
              src={ChatTreeLogo}
              alt="Chat Tree Logo"
              className="w-24 h-24 mx-auto mb-8"
            />
            <h1 className="text-4xl font-bold mb-6">Chat Tree</h1>
            <p className="text-xl mb-8">
              タスク管理機能を備えた次世代のAll-in-One AIサービス
            </p>
          </div>
        </div>
      </section>

      {/* Presentation Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <div className="relative aspect-video">
              <iframe
                src="https://www.slideshare.net/slideshow/embed_code/key/3dncnrAq4kNOpk"
                width="100%"
                height="100%"
                frameBorder="0"
                marginWidth="0"
                marginHeight="0"
                scrolling="no"
                title="Chat Tree 製品紹介スライド"
                className="absolute inset-0 w-full h-full border border-gray-200 rounded-lg shadow-lg"
                allowFullScreen
              />
            </div>
            <div className="mt-4 text-center text-sm text-gray-600">
              <a
                href="https://www.slideshare.net/slideshow/chat-tree-chat-tree-ai-pdf/273750890"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#004aad]"
              >
                Chat Tree 製品案内 - 次世代のAIチャットボットサービス
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Main Features */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">
                8種類の最新AIモデルを搭載
              </h2>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <svg
                    className="w-6 h-6 text-[#38b6ff] mt-1 mr-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div>
                    <span className="font-semibold">GPT-4 & Gemini</span>
                    <p className="text-gray-600">
                      最新のテキスト生成AIモデルを活用し、高度な文章生成が可能
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <svg
                    className="w-6 h-6 text-[#38b6ff] mt-1 mr-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div>
                    <span className="font-semibold">Stable Diffusion</span>
                    <p className="text-gray-600">
                      高品質な画像生成AIで視覚的なコンテンツを作成
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <svg
                    className="w-6 h-6 text-[#38b6ff] mt-1 mr-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div>
                    <span className="font-semibold">その他多数のAIモデル</span>
                    <p className="text-gray-600">
                      目的に応じて最適なAIモデルを選択可能
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <img
                src={MainScreenInterface}
                alt="Chat Tree Interface"
                className="rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Unique Features */}
      <section className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            他社にない独自機能
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="w-12 h-12 bg-[#004aad] rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">タスク管理機能</h3>
              <p className="text-gray-600">
                チャット内容から自動的にタスクを作成し、効率的な業務管理を実現します。
              </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="w-12 h-12 bg-[#38b6ff] rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">ファイル編集機能</h3>
              <p className="text-gray-600">
                送信したファイルを直接編集できる、業界初の機能を搭載しています。
              </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="w-12 h-12 bg-[#004aad] rounded-full flex items-center justify-center mb-4">
                <svg
                  className="w-6 h-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">AIモデル切り替え</h3>
              <p className="text-gray-600">
                同一チャット内で異なるAIモデルを柔軟に切り替えることができます。
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Security Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            安心のセキュリティ
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-start p-6 bg-gray-50 rounded-xl">
              <svg
                className="w-8 h-8 text-[#004aad] mt-1 mr-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                />
              </svg>
              <div>
                <h3 className="text-xl font-semibold mb-2">通信の暗号化</h3>
                <p className="text-gray-600">
                  すべての通信を暗号化し、ファイルも強度の高い暗号方式で保護しています。
                </p>
              </div>
            </div>
            <div className="flex items-start p-6 bg-gray-50 rounded-xl">
              <svg
                className="w-8 h-8 text-[#38b6ff] mt-1 mr-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                />
              </svg>
              <div>
                <h3 className="text-xl font-semibold mb-2">Azure採用</h3>
                <p className="text-gray-600">
                  Microsoft
                  Azureの高セキュリティなインフラストラクチャを採用しています。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-r from-[#004aad] to-[#38b6ff] text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">Chat Treeを無料で体験</h2>
          <div className="flex justify-center gap-4">
            <a
              href="https://chat-tree.com"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-[#004aad] px-8 py-3 rounded-lg font-semibold hover:bg-opacity-90 transition"
            >
              無料プランを試す
            </a>
            <Link
              to="/contact"
              className="border-2 border-white px-8 py-3 rounded-lg font-semibold hover:bg-white hover:text-[#004aad] transition"
            >
              資料請求
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
