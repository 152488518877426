// src/pages/ServiceDetail.js
import React from "react";
import { useParams } from "react-router-dom";

function ServiceDetail() {
  const { serviceId } = useParams();

  const serviceDetails = {
    ChatTree: {
      title: "Chat Tree",
      content: "AIを活用した高度なチャットボットソリューション。",
    },
  };

  const service = serviceDetails[serviceId];

  if (!service) {
    return <p>サービスが見つかりません。</p>;
  }

  return (
    <div className="bg-gray-100 text-gray-800 min-h-screen p-8">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-8">{service.title}</h1>
        <p className="text-lg">{service.content}</p>
      </div>
    </div>
  );
}

export default ServiceDetail;
