// src/pages/Contact.js
import React, { useState } from "react";
import axios from "axios";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "お名前は必須です。";
    if (!formData.email) {
      tempErrors.email = "メールアドレスは必須です。";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "有効なメールアドレスを入力してください。";
    }
    if (!formData.message) tempErrors.message = "メッセージは必須です。";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.copelnix.com";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    axios
      .post(`${API_BASE_URL}/contact`, formData)
      .then((response) => {
        alert(response.data.message);
        setFormData({ name: "", email: "", message: "" });
        setErrors({});
      })
      .catch((error) => {
        console.error("メッセージの送信中にエラーが発生しました。", error);
        alert(
          error.response?.data?.message ||
            "メッセージの送信中にエラーが発生しました。"
        );
      });
  };


  return (
    <div className="bg-gray-100 text-gray-800 min-h-screen p-8">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center">お問い合わせ</h1>
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <form
              onSubmit={handleSubmit}
              className="bg-white p-6 rounded shadow"
            >
              <label className="block mb-4">
                <span className="text-gray-700">お名前</span>
                <input
                  type="text"
                  name="name"
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm ${
                    errors.name && "border-red-500"
                  }`}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && (
                  <span className="text-red-500 text-sm">{errors.name}</span>
                )}
              </label>
              <label className="block mb-4">
                <span className="text-gray-700">メールアドレス</span>
                <input
                  type="email"
                  name="email"
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm ${
                    errors.email && "border-red-500"
                  }`}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <span className="text-red-500 text-sm">{errors.email}</span>
                )}
              </label>
              <label className="block mb-4">
                <span className="text-gray-700">メッセージ</span>
                <textarea
                  name="message"
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm ${
                    errors.message && "border-red-500"
                  }`}
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                {errors.message && (
                  <span className="text-red-500 text-sm">{errors.message}</span>
                )}
              </label>
              <button
                type="submit"
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
              >
                送信
              </button>
            </form>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">所在地</h2>
            <p className="mb-4">〒123-4567 東京都新宿区新宿1-2-3</p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18..."
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Copelnix所在地"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
