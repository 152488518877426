// src/pages/About.js
import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-[#004aad] to-[#38b6ff] text-white py-16">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold mb-6 text-center">会社概要</h1>
          <p className="text-xl text-center max-w-2xl mx-auto">
            Copelnixは、コペルニクス的転回のように、
            既存の常識を根本から変え、新しい価値を創造します。
          </p>
        </div>
      </section>

      {/* Company Info Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="grid md:grid-cols-2 divide-y md:divide-y-0 md:divide-x divide-gray-200">
                <div className="p-8">
                  <h2 className="text-2xl font-semibold mb-6">基本情報</h2>
                  <dl className="space-y-4">
                    <div>
                      <dt className="font-semibold text-gray-600">会社名</dt>
                      <dd className="mt-1">Copelnix株式会社</dd>
                      <dd className="text-gray-600">Copelnix Inc.</dd>
                    </div>
                    <div>
                      <dt className="font-semibold text-gray-600">設立</dt>
                      <dd className="mt-1">2024年10月31日</dd>
                    </div>
                    <div>
                      <dt className="font-semibold text-gray-600">代表者</dt>
                      <dd className="mt-1">鈴木 爽太郎</dd>
                    </div>
                    <div>
                      <dt className="font-semibold text-gray-600">資本金</dt>
                      <dd className="mt-1">1,000,000円</dd>
                    </div>
                  </dl>
                </div>
                <div className="p-8">
                  <h2 className="text-2xl font-semibold mb-6">事業内容</h2>
                  <dl className="space-y-4">
                    <div>
                      <dt className="font-semibold text-gray-600">所在地</dt>
                      <dd className="mt-1">
                        〒116-0014
                        <br />
                        東京都荒川区東日暮里5-16-1
                      </dd>
                    </div>
                    <div>
                      <dt className="font-semibold text-gray-600">主な事業</dt>
                      <dd className="mt-1">
                        <ul className="list-disc list-inside space-y-2">
                          <li>AIチャットボット「Chat Tree」の開発・運営</li>
                          <li>その他AI関連サービスの提供</li>
                        </ul>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8">Copelnixとは</h2>
            <p className="text-lg mb-8 leading-relaxed">
              Copelnix（コペルニクス）は、コペルニクス的転回で有名な天文学者コペルニクスに由来しています。
              私たちは、既存の常識を根本から変え、全く新しい常識を見つけ出すことを目指しています。
            </p>
            <p className="text-lg leading-relaxed">
              最先端のAI技術を活用し、ビジネスや社会に革新的なソリューションを提供することで、
              人々の生活を豊かにし、新たな価値を創造します。
            </p>
          </div>
        </div>
      </section>

      {/* Mission & Values Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Mission & Values</h2>
            <p className="text-2xl font-semibold text-[#004aad]">
              To Revolutionize the Common Sense
            </p>
            <p className="text-xl text-gray-600">常識を革命する</p>
          </div>
          <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="w-16 h-16 bg-[#38b6ff] rounded-full flex items-center justify-center mx-auto mb-4">
                <svg
                  className="w-8 h-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-center mb-4">
                Innovation
              </h3>
              <p className="text-center text-gray-600">
                常に革新的な技術とアイデアで、新しい価値を創造します。
              </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="w-16 h-16 bg-[#004aad] rounded-full flex items-center justify-center mx-auto mb-4">
                <svg
                  className="w-8 h-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-center mb-4">
                Collaboration
              </h3>
              <p className="text-center text-gray-600">
                多様な才能と背景を持つチームで協力し、最高の成果を追求します。
              </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="w-16 h-16 bg-[#38b6ff] rounded-full flex items-center justify-center mx-auto mb-4">
                <svg
                  className="w-8 h-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-center mb-4">
                Integrity
              </h3>
              <p className="text-center text-gray-600">
                高い倫理観と責任感を持って、信頼されるサービスを提供します。
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="bg-gradient-to-r from-[#004aad] to-[#38b6ff] text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">お問い合わせ</h2>
          <p className="text-xl mb-8">
            Copelnixへのお問い合わせは、以下のボタンからお気軽にご連絡ください。
          </p>
          <Link
            to="/contact"
            className="inline-block bg-white text-[#004aad] px-8 py-3 rounded-lg font-semibold hover:bg-opacity-90 transition"
          >
            お問い合わせページへ
          </Link>
        </div>
      </section>
    </div>
  );
}

export default About;
