// src/components/Footer.js

import React from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
} from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="container mx-auto px-4 grid md:grid-cols-4 gap-8">
        {/* サービス内容 */}
        <div>
          <h3 className="text-xl font-semibold mb-4">サービス内容</h3>
          <ul>
            <li>
              <Link to="/chat-tree" className="hover:underline">
                Chat Tree
              </Link>
            </li>
            {/* 他のサービス */}
          </ul>
        </div>
        {/* 他のセクション */}
      </div>
      {/* SNSアイコン */}
      <div className="container mx-auto px-4 mt-8">
        <div className="flex justify-center space-x-6">
          {/* SNSアイコン */}
          <a
            href="https://twitter.com/yourprofile"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-400"
          >
            <FaTwitter size={24} />
          </a>
          {/* 他のSNSアイコン */}
        </div>
      </div>
      {/* コピーライト */}
      <div className="text-center mt-8">
        <ul className="flex justify-center space-x-4 mb-4">
          <li>
            <Link to="/privacy-policy" className="hover:underline">
              プライバシーポリシー
            </Link>
          </li>
          <li>
            <Link to="/terms-of-service" className="hover:underline">
              利用規約
            </Link>
          </li>
        </ul>
        <p>
          &copy; {new Date().getFullYear()} Copelnix株式会社. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
