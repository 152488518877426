// src/pages/Recruit.js

import React from "react";

function Recruit() {
  return (
    <div className="bg-white text-gray-800 min-h-screen p-8">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center">採用情報</h1>
        <p className="mb-8 text-center">
          私たちは、未来を一緒に創造する仲間を探しています。
        </p>
        {/* 採用情報の詳細をここに追加 */}
        <div className="grid md:grid-cols-2 gap-8">
          {/* 職種カード */}
          <div className="bg-gray-100 p-6 rounded-lg shadow">
            <h2 className="text-2xl font-semibold mb-4">
              ソフトウェアエンジニア
            </h2>
            <p>AIアプリケーションの開発を担当します。</p>
          </div>
          {/* 他の職種を追加 */}
        </div>
      </div>
    </div>
  );
}

export default Recruit;
