import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import ServiceDetail from "./pages/ServiceDetail";
import Contact from "./pages/Contact";
import Announcements from "./pages/Announcements";
import Recruit from "./pages/Recruit";
import Footer from "./components/Footer";

// ブログ記事のコンポーネントをインポート
import UniversityEntranceExam from "./pages/BlogPosts/UniversityEntranceExam";
import CPAExam from "./pages/BlogPosts/CPAExam";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="pt-16">
        {/* ナビゲーションバーの高さを考慮 */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:serviceId" element={<ServiceDetail />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/recruit" element={<Recruit />} />
          <Route path="/contact" element={<Contact />} />
          {/* ブログ記事へのルートを追加 */}
          <Route
            path="/blog/university-entrance-exam"
            element={<UniversityEntranceExam />}
          />
          <Route path="/blog/cpa-exam" element={<CPAExam />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
