import React, { useEffect } from "react";

function UniversityEntranceExam() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white text-gray-800 min-h-screen p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">
        センター試験をChatGPTで解かせてみた
      </h1>
      <div className="max-w-3xl mx-auto">
        <p className="mb-4">2024年10月10日</p>
        <p className="mb-4">
          ChatGPTにセンター試験を解かせ、その結果と考察をまとめました。
        </p>

        {/* ブログの内容を書く */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">はじめに</h2>
        <p className="mb-4">
          OpenAIが開発したChatGPTは、自然言語処理において非常に高い性能を持つAIモデルです。今回は、このChatGPTに日本の大学入試であるセンター試験の問題を解かせてみました。
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">試験の概要</h2>
        <p className="mb-4">
          センター試験は、日本の大学入試で多くの大学が採用していた共通試験です。試験科目は国語、数学、英語、理科、社会など多岐にわたります。
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">結果</h2>
        <p className="mb-4">
          ChatGPTに各科目の問題を解かせた結果、以下のような成績を収めました。
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>国語: 80%</li>
          <li>数学: 95%</li>
          <li>英語: 100%</li>
          <li>理科: 85%</li>
          <li>社会: 90%</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-8 mb-4">考察</h2>
        <p className="mb-4">
          ChatGPTは特に英語と数学で高い正答率を示しました。一方で、国語の一部問題や理科の詳細な知識を問う問題ではミスが見られました。
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">結論</h2>
        <p className="mb-4">
          全体として、ChatGPTはセンター試験において非常に優秀な成績を収めることができました。これはAIが高度な教育レベルに対応できる可能性を示しています。
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">今後の展望</h2>
        <p className="mb-4">
          今後は、さらに専門的な試験や他のAIモデルとの比較も行い、AIの能力をより深く探求していきたいと思います。
        </p>
      </div>
    </div>
  );
}

export default UniversityEntranceExam;
