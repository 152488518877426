// src/pages/Home.js
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MainScreenInterface from "../assets/main-screen-interface.png"; // パスを適宜調整してください
import AImodels from "../assets/ai-models.png"; // パスを適宜調整してください

function Home() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Copelnix - 次世代のAIソリューション</title>
        <meta
          name="description"
          content="Copelnixは、最新のAI技術を活用したAll-in-Oneソリューション「Chat Tree」を提供します。"
        />
      </Helmet>

      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-[#004aad] to-[#38b6ff] text-white">
        <div className="container mx-auto px-4 py-24">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-5xl font-bold mb-6">
                次世代のAll-in-One AIサービス
              </h1>
              <p className="text-xl mb-8">
                Chat
                Treeは、タスク管理機能を備えた革新的なAIチャットボットです。
                最新の生成AIモデルと柔軟な操作性で、ビジネスの効率を大幅に向上させます。
              </p>
              <div className="flex gap-4">
                <a
                  href="https://chat-tree.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white text-[#004aad] px-8 py-3 rounded-lg font-semibold hover:bg-opacity-90 transition"
                >
                  サービスを試す
                </a>
                <Link
                  to="/contact"
                  className="border-2 border-white px-8 py-3 rounded-lg font-semibold hover:bg-white hover:text-[#004aad] transition"
                >
                  お問い合わせ
                </Link>
              </div>
            </div>
            <div className="relative">
              <img
                src={MainScreenInterface}
                alt="Chat Tree インターフェース"
                className="rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Presentation Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">製品紹介</h2>
          <div className="max-w-3xl mx-auto">
            <div className="relative aspect-video">
              <iframe
                src="https://www.slideshare.net/slideshow/embed_code/key/3dncnrAq4kNOpk"
                width="100%"
                height="100%"
                frameBorder="0"
                marginWidth="0"
                marginHeight="0"
                scrolling="no"
                title="Chat Tree 製品紹介スライド"
                className="absolute inset-0 w-full h-full border border-gray-200 rounded-lg shadow-lg"
                allowFullScreen
              />
            </div>
            <div className="mt-4 text-center text-sm text-gray-600">
              <a
                href="https://www.slideshare.net/slideshow/chat-tree-chat-tree-ai-pdf/273750890"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#004aad]"
              >
                Chat Tree 製品案内 - 次世代のAIチャットボットサービス
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">
            Chat Treeの特長
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center p-6 rounded-xl bg-gray-50">
              <img
                src={AImodels}
                alt="AI Models"
                className="w-31 h-20 mx-auto mb-4"
              />
              <h3 className="text-2xl font-semibold mb-4">
                最新の生成AIモデル
              </h3>
              <p>
                GPT-4やGemini、Stable Diffusionなど、
                8種類の最新AIモデルを利用可能です。
              </p>
            </div>
            <div className="text-center p-6 rounded-xl bg-gray-50">
              <div className="w-16 h-16 bg-[#38b6ff] rounded-full mx-auto mb-4 flex items-center justify-center">
                <svg
                  className="w-8 h-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">タスク管理機能</h3>
              <p>
                チャット内容から自動的にタスクを作成し、
                効率的なタスク管理を実現します。
              </p>
            </div>
            <div className="text-center p-6 rounded-xl bg-gray-50">
              <div className="w-16 h-16 bg-[#004aad] rounded-full mx-auto mb-4 flex items-center justify-center">
                <svg
                  className="w-8 h-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                  />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-4">柔軟な操作性</h3>
              <p>
                ファイル編集機能や複数AIモデルの切り替えなど、
                他社にはない便利な機能を搭載しています。
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-[#004aad] text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-8">業務効率を革新的に改善</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Chat Treeを導入して、AIの力でビジネスを次のレベルへ。
            まずは無料プランから始めてみませんか？
          </p>
          <div className="flex justify-center gap-4">
            <a
              href="https://chat-tree.com"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-[#004aad] px-8 py-3 rounded-lg font-semibold hover:bg-opacity-90 transition"
            >
              無料で始める
            </a>
            <Link
              to="/contact"
              className="border-2 border-white px-8 py-3 rounded-lg font-semibold hover:bg-white hover:text-[#004aad] transition"
            >
              資料請求
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
