import React, { useEffect } from "react";

function CPAExam() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white text-gray-800 min-h-screen p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">
        公認会計士試験をChatGPTで解かせてみた
      </h1>
      <div className="max-w-3xl mx-auto">
        <p className="mb-4">2024年10月15日</p>
        <p className="mb-4">
          ChatGPTに公認会計士試験を解かせた結果をご紹介します。
        </p>
        {/* ブログの内容を書く */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">はじめに</h2>
        <p className="mb-4">
          公認会計士試験は、日本でも最難関の国家資格試験の一つです。今回は、ChatGPTの専門知識をテストするため、この試験の問題を解かせてみました。
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">試験の概要</h2>
        <p className="mb-4">
          公認会計士試験は、会計学、監査論、企業法、租税法などの科目からなります。それぞれが高度な専門知識を必要とします。
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">結果</h2>
        <p className="mb-4">ChatGPTの回答は以下の通りでした。</p>
        <ul className="list-disc list-inside mb-4">
          <li>会計学: 70%</li>
          <li>監査論: 65%</li>
          <li>企業法: 80%</li>
          <li>租税法: 60%</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-8 mb-4">考察</h2>
        <p className="mb-4">
          専門的な法律知識が必要な企業法では比較的高い正答率を示しましたが、租税法や監査論などでは知識不足が見られました。
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">結論</h2>
        <p className="mb-4">
          ChatGPTは公認会計士試験において一定の理解力を示しましたが、合格ラインには達しませんでした。専門的な知識を必要とする分野では、まだ人間の専門家に及ばないことが分かりました。
        </p>
        <h2 className="text-2xl font-semibold mt-8 mb-4">今後の展望</h2>
        <p className="mb-4">
          AIの専門知識を強化することで、さらに高度なタスクへの対応が期待されます。継続的な学習とモデルの改良が鍵となります。
        </p>
      </div>
    </div>
  );
}

export default CPAExam;
