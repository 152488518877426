import React, { useState } from "react";
import { Link } from "react-router-dom";

function Announcements() {
  const [selectedTag, setSelectedTag] = useState("全て");

  const announcements = [
    {
      title: "新サービスリリースのお知らせ",
      date: "2024年10月1日",
      tag: "ニュースリリース",
      content: "新しいAIチャットボット「Chat Tree」をリリースしました。",
    },
    {
      title: "メディア掲載情報",
      date: "2024年10月5日",
      tag: "メディア掲載",
      content: "当社のサービスがTech Newsに掲載されました。",
    },
    {
      title: "センター試験をChatGPTで解かせてみた",
      date: "2024年10月10日",
      tag: "ブログ",
      content: "ChatGPTにセンター試験を解かせた結果をご報告します。",
      link: "/blog/university-entrance-exam",
    },
    {
      title: "公認会計士試験をChatGPTで解かせてみた",
      date: "2024年10月15日",
      tag: "ブログ",
      content: "ChatGPTに公認会計士試験を解かせた結果をご紹介します。",
      link: "/blog/cpa-exam",
    },
    // 他のお知らせを追加できます
  ];

  const tags = ["全て", "ニュースリリース", "メディア掲載", "ブログ"];

  const filteredAnnouncements =
    selectedTag === "全て"
      ? announcements
      : announcements.filter((item) => item.tag === selectedTag);

  return (
    <div className="bg-white text-gray-800 min-h-screen p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">お知らせ</h1>

      {/* タグボタン */}
      <div className="flex justify-center mb-8">
        {tags.map((tag) => (
          <button
            key={tag}
            onClick={() => setSelectedTag(tag)}
            className={`mx-2 px-4 py-2 rounded-full font-semibold ${
              selectedTag === tag
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-800"
            } hover:bg-blue-600 hover:text-white transition duration-300`}
          >
            {tag}
          </button>
        ))}
      </div>

      {/* お知らせリスト */}
      <div className="space-y-6">
        {filteredAnnouncements.map((item, index) => (
          <Link
            key={index}
            to={item.link || "#"}
            className={`block bg-gray-100 p-6 rounded-lg hover:bg-gray-200 transition duration-300 ${
              !item.link && "cursor-default"
            }`}
          >
            <h2 className="text-2xl font-semibold mb-2 text-blue-600">
              {item.title}
            </h2>
            <p className="text-gray-600 mb-2">{item.date}</p>
            <p className="mb-2">
              <span className="px-2 py-1 bg-blue-600 text-white rounded-full text-sm">
                {item.tag}
              </span>
            </p>
            <p>{item.content}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Announcements;
